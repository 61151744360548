export const ZONE = {
  UTC: 'utc',
  LOCAL: 'local'
};

export const TIME_ZONE = {
  SEOUL: 'Asia/Seoul',
  TOKYO: 'Asia/Tokyo',
  BEIJING: 'Asia/Shanghai',
  TAIPEI: 'Asia/Taipei',
  LONDON: 'Europe/London',
  NEW_YORK: 'America/New_York'
};

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const YEAR_FORMAT = 'yyyy-MM-dd';

export const YEAR_FORMAT_WITH_DOT = 'yyyy.MM.dd';
export const DATE_TIME_FORMAT_WITH_DOT = 'yyyy.MM.dd HH:mm';
export const MONTH_FORMAT_WITH_DOT = 'yyyy.MM';
export const MONTH_DAY_FORMAT = 'M/d';

export const DATE_MONTH_DOMESTIC = 'MM.dd';
export const DATE_MONTH_INTERNATIONAL = 'dd.MM';
export const MONTH_YEAR_DOMESTIC = 'yyyy.MM';
export const MONTH_YEAR_INTERNATIONAL = 'MM.yyyy';
export const DATE_TIME_DOMESTIC = 'yyyy.MM.dd HH:mm';
export const DATE_TIME_INTERNATIONAL = 'MM.dd.yyyy HH:mm';
export const DATE_TIME_DOMESTIC_WITHOUT_HOUR = 'yyyy.MM.dd';
export const DATE_TIME_INTERNATIONAL_WITHOUT_HOUR = 'MM.dd.yyyy';

export const DATE_WITH_DAY_NAME_FORMAT = 'LLLLL EEEE'; // Monday the 18th
export const DATE_WITH_SHORT_DAY_NAME_FORMAT = 'LLLLL'; // 9월
export const DATE_WITH_THE_DAY_OF_THE_WEEK = 'EEEE'; // Monday

export const MINIMUM_AGES_ALLOWED_TO_REGISTER_IN_MILISECONDS = 441797328000; // fourteen years in miniseconds
